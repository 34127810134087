import gql from 'graphql-tag';
import { apolloClient as apollo } from '@/vue-apollo';
import { PROJECT_CARBON_FIELD } from '@/shared/constants';
import GlobalMixin from '@/mixins/global.mixin';

const EXTERNAL_RES_FIELD = `
  id
  createdAt
  updatedAt
  projectId
  userId
  carbonDateTime
  description
  amount
  amountVat
  amountTotal
  supplierId
  documentXid
  originalFileName
  parentId
  category
  children {
    id
    createdAt
    updatedAt
    projectId
    userId
    carbonDateTime
    description
    amount
    amountVat
    amountTotal
    supplierId
    documentXid
    originalFileName
    parentId
    carbon {
      ${PROJECT_CARBON_FIELD}
    }
  }
  carbon {
    ${PROJECT_CARBON_FIELD}
  }
`;

const CUMPUTER_EQUIPMENT_FIELDS = `{
	id
	locationId
	type
	description
	quantity
	depreciation
}`;

const LOCATION_DATE = `{
	id
	locationId
	date
	location {
		id
	}
}`;

const LOCATION_FIELDS = `{
	id
    name
    typeOfDecor
	type2
    countryId
    isPreproduction
	isShooting
	countryId
	filmingArea
	noOfDaysOfUse
	buildingLessThan30Years
	useGridEnergy
	isPlaceHeated
	isPlaceAirConditioned
	noOfAirConditioners
	electricityConsumption
	isElectricityFromPhotovoltaicSource
	useAncilliarySpace
	electricityAncillarySpaceConsumption
	isElectricityAncillarySpaceFromPhotovoltaicSource
	gasConsumption
	fuelOilConsumption
	surfaceAreaOfFixtures
	isElectricityConsumptionEstimated
	isHeatingConsumptionEstimated
	heatingType
	electricityEstimatedConsumption
	heatingEstimatedConsumption
}`;

const PREMISE_FIELDS = `{
	id
    name
    typeOfPremise
    countryId
    areaOfPremise
	isPreproduction
	isShooting
	isPostProduction
    totalOccupationDay
    totalNbOfManDays
	workingFromHomeActivity
	buildingLessThan30Year
	isPlaceHeated
	heatingType
	isPlaceAirConditioned
    airConditioners
	estimation
    electricityTotalConsumption
    isElectricityFromPhotovoltaicSource
    gasTotalConsumption
    fuelOilTotalConsumption
	isElectricityConsumptionEstimated
	isHeatingConsumptionEstimated
	electricityEstimatedConsumption
	heatingEstimatedConsumption
    equipments {
        locationId
        type
        description
        quantity
		depreciation
    }
}`;

/**
 * DECORATION
 */
const CARBON_DECORATION_FIELDS = `
	costPurchaseSetsTotal
	costMakingSetsTotal
	costRentingSetsTotal
	costCreationSetsTotal
	paintLiterWasteTotal
	cardboardWasteTotal
	woodWasteTotal
	steelWasteTotal
	plasticWasteTotal
	costSecondHandCostumesPurchase
	filmVehicleGasolineCarDistance
	filmVehicleDieselCarDistance
	filmVehicleElectricCarDistance
	filmVehicleTruckDistance
	filmVehicleOutboardBoatFuelConsumption
	filmVehicleOtherFuelConsumption
	filmVehiclePlaneFuelConsumption
`;

/**
 * Costumes, Hairs and Makeups
 */
const CARBON_COSTUMES_HAIRS_MAKEUPS_FIELDS = `
	costumeBuyTotal
	costumeMakingTotal
	costumeRentalTotal
	makeupTotal
	hairCostTotal
	secondHandCostumesPurchase
`;

/**
 * Transportation
 */
const CARBON_TRANSPORTATION_FIELDS = `
	gasolineCarKm {
	    total
        preproductionTotal
        shootingTotal
        postProductionTotal
        notDefinedTotal
        weight
	}
    electricCarKm {
	    total
        preproductionTotal
        shootingTotal
        postProductionTotal
        notDefinedTotal
        weight
	}
    dieselCarKm {
	    total
        preproductionTotal
        shootingTotal
        postProductionTotal
        notDefinedTotal
        weight
	}
    taxiKm {
	    total
        preproductionTotal
        shootingTotal
        postProductionTotal
        notDefinedTotal
        weight
	}
    hybridCarKm {
	    total
        preproductionTotal
        shootingTotal
        postProductionTotal
        notDefinedTotal
        weight
	}
    minibusVanKm {
	    total
        preproductionTotal
        shootingTotal
        postProductionTotal
        notDefinedTotal
        weight
	}
    motorCycleUpper250CcKm {
	    total
        preproductionTotal
        shootingTotal
        postProductionTotal
        notDefinedTotal
        weight
	}
    busKm {
	    total
        preproductionTotal
        shootingTotal
        postProductionTotal
        notDefinedTotal
        weight
	}
    gasolineScooterKm {
	    total
        preproductionTotal
        shootingTotal
        postProductionTotal
        notDefinedTotal
        weight
	}
    trainKm {
	    total
        preproductionTotal
        shootingTotal
        postProductionTotal
        notDefinedTotal
        weight
	}
    electricScooterKm {
	    total
        preproductionTotal
        shootingTotal
        postProductionTotal
        notDefinedTotal
        weight
	}
    terKm {
	    total
        preproductionTotal
        shootingTotal
        postProductionTotal
        notDefinedTotal
        weight
	}
    planeKm {
	    total
        preproductionTotal
        shootingTotal
        postProductionTotal
        notDefinedTotal
        weight
	}
    sailBoatKm {
	    total
        preproductionTotal
        shootingTotal
        postProductionTotal
        notDefinedTotal
        weight
	}
    privateJetKm {
	    total
        preproductionTotal
        shootingTotal
        postProductionTotal
        notDefinedTotal
        weight
	}
    zodiacKm {
	    total
        preproductionTotal
        shootingTotal
        postProductionTotal
        notDefinedTotal
        weight
	}
    ferryKm {
	    total
        preproductionTotal
        shootingTotal
        postProductionTotal
        notDefinedTotal
        weight
	}
    yachtKm {
	    total
        preproductionTotal
        shootingTotal
        postProductionTotal
        notDefinedTotal
        weight
	}
    sailBoatL {
	    total
        preproductionTotal
        shootingTotal
        postProductionTotal
        notDefinedTotal
        weight
	}
    zodiacL {
	    total
        preproductionTotal
        shootingTotal
        postProductionTotal
        notDefinedTotal
        weight
	}
    yachtL {
	    total
        preproductionTotal
        shootingTotal
        postProductionTotal
        notDefinedTotal
        weight
	}
    ferryL {
	    total
        preproductionTotal
        shootingTotal
        postProductionTotal
        notDefinedTotal
        weight
	}
    sailBoatMin {
	    total
        preproductionTotal
        shootingTotal
        postProductionTotal
        notDefinedTotal
        weight
	}
    zodiacMin {
	    total
        preproductionTotal
        shootingTotal
        postProductionTotal
        notDefinedTotal
        weight
	}
    yachtMin {
	    total
        preproductionTotal
        shootingTotal
        postProductionTotal
        notDefinedTotal
        weight
	}
    groupTravelInsideUrbanAreas
    groupTravelOutsideUrbanAreas
	freights {
		total
		preproductionTotal
		shootingTotal
		postProductionTotal
		notDefinedTotal
		weight
		type
		description
	}
    freightContainerShipByBoat {
	    total
        preproductionTotal
        shootingTotal
        postProductionTotal
        notDefinedTotal
        weight
	}
    freightPlane {
	    total
        preproductionTotal
        shootingTotal
        postProductionTotal
        notDefinedTotal
        weight
	}
    freightTrain {
	    total
        preproductionTotal
        shootingTotal
        postProductionTotal
        notDefinedTotal
        weight
	}
    freightTruckLower {
	    total
        preproductionTotal
        shootingTotal
        postProductionTotal
        notDefinedTotal
        weight
	}
    freightTruckUpper {
	    total
        preproductionTotal
        shootingTotal
        postProductionTotal
        notDefinedTotal
        weight
	}
    freightUtilityVan {
	    total
        preproductionTotal
        shootingTotal
        postProductionTotal
        notDefinedTotal
        weight
	}
`;

/**
 * Management
 */
const CARBON_MANAGEMENT_FIELDS = `
	wasteEstimation
	nbOfShootingDays
	nbOfPersons
	setSmallConsumableTotal
	setMaterialSorted
	setCardboardWasteTotal
	setPaintLiterWasteTotal
	setWoodWasteTotal
	setSteelWasteTotal
	setPlasticWasteTotal
	setMixedWasteTotal
	setRecycledWaste
	setFoodWasteTotal
	setGlassWasteTotal
	setSpecialWasteTotal
	plasticWasteTotal
	woodWasteTotal
	nbOvernight
	nbRedMeatCatering
	nbWhiteMeatCatering
	nbVegetarianCatering
	nbNotSpecifiedCatering
	estimatedWasteAmount
	craftServiceTableExpense
	cardboardUnit
	paperUnit
	plasticUnit
	generalWasteUnit
	foodUnit
	glassUnit
	paintSpecialWasteUnit
	plasticDestructionUnit
	woodUnit
	steelUnit
`;

/**
 * TECHNICAL
 */
const CARBON_TECHNICAL_FIELDS = `
	nbrBatteriesThrown
	amountCameraRental
	amountLightingRental
	amountMachineryRental
	amountSoundMaterialRental
	amountOfFilmsSupport
	amountOfSpecialEffect
	sumCarsLogesKm
	sizeOfCarLoge
	energyTypeOfCarLoge
	volumeEnergyCarLoge
	carDiesel
	carElectric
	carGasoline
	ulmPlaneClassTwo
	motoLarge
	helicopter
	ulmPlaneClassThree
	outBoardBoat
	technicalMeans {
	  name
    type
    daysInUse
    cost
    travelDistance
    energySourceUsed
    energyType
    fuelConsumption
    isPreproduction
    isShooting
	}
	technicalCosts {
	  name
    type
    daysInUse
    cost
    travelDistance
    energySourceUsed
    energyType
    fuelConsumption
    isPreproduction
    isShooting
	}
	technicalProductionTrailer {
	  name
    type
    daysInUse
    cost
    travelDistance
    energySourceUsed
    energyType
    fuelConsumption
    isPreproduction
    isShooting
	}
	technicalGenerators {
	  name
    type
    daysInUse
    cost
    travelDistance
    energySourceUsed
    energyType
    fuelConsumption
    isPreproduction
    isShooting
	}
`;

/**
 * Post PRODUCTION
 */
const CARBON_POST_PRODUCTION_FIELDS = `
    postProductionCosts {
        countryId
        videoEditing
        soundEditingAndMixing
        vfx
        colorGrading
        masteringAndLaboratory
    }
    videoEditing {
        postProductionMeans
        nbWorkingDays
        nbExtraStaffDays
        countryId
    }
    soundEditing {
        postProductionMeans
        nbWorkingDays
        nbExtraStaffDays
        countryId
    }
    digitalVisualEffect {
        postProductionMeans
        nbWorkingDays
        nbExtraStaffDays
        countryId
    }
    calibration {
        postProductionMeans
        nbWorkingDays
        nbExtraStaffDays
        countryId
    }
    laboratory {
        postProductionMeans
        nbWorkingDays
        nbExtraStaffDays
        countryId
    }
    dataArchivedLTO
    numCopiesArchivedLTO
    dataArchivedHDD
    numCopiesArchivedHDD
    dataArchivedOnCloud
    numCopiesArchivedOnCloud
`;

const CARBON_OTHERS_FIELDS = `
	productionOffices ${PREMISE_FIELDS}
	setDesignOffices ${PREMISE_FIELDS}
	hmcWorkplaceAndStorageOffices ${PREMISE_FIELDS}
	indoorLocations ${LOCATION_FIELDS}
	outdoorLocations ${LOCATION_FIELDS}
`;

const RES_CARBON_CLAP = `
	id
	createdAt
	updatedAt
	projectId
	finalReport
	ccProjectName
	userId
	currency
	productionType
	budget
	totalProduction
	numberOfEpisode
	filmLength
	shootingFormat
	startDate
	endDate
	production
	countryId
	coProdCountries
	coProdCountriesId {
	    value
	}
	broadcastMode
	shootingTypology
	totInsurances
	totServices
	sendStatus
	sendLastDateTime
	sendEstimatedTime
	logFileXid
	${CARBON_DECORATION_FIELDS}
	${CARBON_COSTUMES_HAIRS_MAKEUPS_FIELDS}
	${CARBON_TRANSPORTATION_FIELDS}
	${CARBON_MANAGEMENT_FIELDS}
	${CARBON_TECHNICAL_FIELDS}
	${CARBON_POST_PRODUCTION_FIELDS}
	${CARBON_OTHERS_FIELDS}
`;

const RES_CARBON_TEMPLATE = `
  id
  licenseId
  numberOfUse
  efficiency
  name
  coTwoCategory
  type
  subType
  km
  liter
  mcube
  kwh
  kg
  numberOfVehicule
  msquare
  costCenters
  number
  boolValue
  mealRed
  mealWhite
  mealVege
  mealNotSpecified
  position
  supplierId
  searchInDescOnly
  keyWordOne
  keyWordTwo
  keyWordThree
  userId
  unitAmountTotal
  vehiculeId
  reason
  additionalSubType
  waterVolumeUsed
  disable
  locationId
  productionPhase
  typeMeasurement
  navigationDuration
  fuelConsumption
  supplier {
    id
    name
  }
  carbonValues {
    id
    carbonId
    carbonModel
    kgCoTwo
  }
  user {
    name
    firstName
  }
`;

export const GetKmGreenImpact = async (km, category, detail) => {
	try {
		const mutation = gql`
			query ($Km: Float!, $Category: Int!, $Detail: Int) {
				GetKmGreenImpact(Km: $Km, Category: $Category, Detail: $Detail)
			}
		`;
		const {
			data //: { AddUpdFormItem }
		} = await apollo.mutate({
			mutation,
			variables: {
				Category: parseInt(category),
				Km: parseFloat(km),
				Detail: +detail
			}
		});

		return data.GetKmGreenImpact;
	} catch (e) {
		console.log({ e });
	}
};

export const moveAndReorganizeCarbonTemplatePosition = async (CarbonTemplateId, Position) => {
	try {
		const mutation = gql`
			mutation ($CarbonTemplateId: ID!, $Position: ID!) {
				MoveAndReorganizeCarbonTemplatePosition(CarbonTemplateId: $CarbonTemplateId, Position: $Position)
			}
		`;

		const {
			data: { MoveAndReorganizeCarbonTemplatePosition }
		} = await apollo.mutate({
			mutation,
			variables: {
				CarbonTemplateId: +CarbonTemplateId,
				Position: +Position
			}
		});

		return MoveAndReorganizeCarbonTemplatePosition;
	} catch (e) {
		console.log(e);
	}
};

export const generateForecastCarbonEntriesBasedOnBudget = async () => {
	try {
		const mutation = gql`
			mutation {
				GenerateForecastCarbonEntriesBasedOnBudget
			}
		`;

		const {
			data: { GenerateForecastCarbonEntriesBasedOnBudget }
		} = await apollo.mutate({
			mutation
		});

		return GenerateForecastCarbonEntriesBasedOnBudget;
	} catch (e) {
		console.log(e);
	}
};

export const getProjectCarbon = async (filter = {}) => {
	try {
		const query = gql`
			query ($filter: AdvancedGlobalFilterInput) {
				GetProjectCarbon(Filter: $filter) {
					${PROJECT_CARBON_FIELD}
				}
			}
		`;

		const {
			data: { GetProjectCarbon }
		} = await apollo.query({
			query,
			variables: {
				filter
			},
			fetchPolicy: 'no-cache'
		});

		return GetProjectCarbon;
	} catch (e) {
		console.log(e);
	}
};

export const updCarbon = async (carbonId, inputCarbon) => {
	const mutation = gql`
		mutation ($carbonId: ID!, $inputCarbon: CarbonInput!) {
			UpdCarbon(CarbonId: $carbonId, UpdCarbon: $inputCarbon) {
				${PROJECT_CARBON_FIELD}
			}
		}
	`;

	const {
		data: { UpdCarbon }
	} = await apollo.mutate({
		mutation,
		variables: {
			carbonId: parseInt(carbonId, 10),
			inputCarbon
		}
	});

	return UpdCarbon;
};

export const newCarbon = async (inputCarbon) => {
	const mutation = gql`
		mutation ($inputCarbon: CarbonInput!) {
			NewCarbon(NewCarbon: $inputCarbon) {
				${PROJECT_CARBON_FIELD}
			}
		}
	`;

	const {
		data: { NewCarbon }
	} = await apollo.mutate({
		mutation,
		variables: {
			inputCarbon
		}
	});

	return NewCarbon;
};

export const getCarbon = async (parentId, entityType) => {
	const query = gql`
		query($parentId: Int!, $entityType: Int!) {
			GetCarbon(ParentId: $parentId, EntityType: $entityType) {
				${PROJECT_CARBON_FIELD}
			}
		}
	`;

	const {
		data: { GetCarbon }
	} = await apollo.query({
		query,
		variables: {
			parentId: +parentId,
			entityType: +entityType
		},
		fetchPolicy: 'no-cache'
	});

	return GetCarbon;
};

export const addUpdCarbonFromExternalSource = async (externalId = null, externalInput = {}, carbonInput = {}) => {
	const mutation = gql`
			mutation ($externalId: ID, $externalInput: CarbonFromExternalSourceInput!) {
				AddUpdCarbonFromExternalSource(
					IdCarbonFromExternalSourceToUpdate: $externalId
					CarbonFromExternalSourceInput: $externalInput
				) {
					${EXTERNAL_RES_FIELD}
				}
			}
		`;

	const {
		data: { AddUpdCarbonFromExternalSource }
	} = await apollo.mutate({
		mutation,
		variables: {
			externalId,
			externalInput
		}
	});

	return AddUpdCarbonFromExternalSource;
};

export const getCarbonFromExternalSource = async (externalId = 0) => {
	const query = gql`
		query ($externalId: ID!) {
			GetCarbonFromExternalSource(CarbonFromExternalSourceId: $externalId) {
				${EXTERNAL_RES_FIELD}
			}
		}
	`;

	const {
		data: { GetCarbonFromExternalSource }
	} = await apollo.query({
		query,
		variables: {
			externalId: parseInt(externalId)
		},
		fetchPolicy: 'no-cache'
	});

	return GetCarbonFromExternalSource;
};

export const delCarbonFromExternalSource = async (carbonExternalSourceId) => {
	const mutation = gql`
		mutation ($carbonExternalSourceId: [ID!]!) {
			DelCarbonFromExternalSource(CarbonExternalSourceId: $carbonExternalSourceId)
		}
	`;

	const {
		data: { DelCarbonFromExternalSource }
	} = await apollo.mutate({
		mutation,
		variables: {
			carbonExternalSourceId
		}
	});

	return DelCarbonFromExternalSource;
};

export const getProjectCarbonClap = async (reporting = false) => {
	try {
		const query = gql`
			query ($reporting: Boolean!) {
				GetProjectCarbonClap(Reporting: $reporting) {
					${RES_CARBON_CLAP}
				}
			}
		`;

		const {
			data: { GetProjectCarbonClap }
		} = await apollo.query({
			query,
			variables: {
				reporting
			},
			fetchPolicy: 'no-cache'
		});

		return GetProjectCarbonClap;
	} catch (e) {
		console.log(e);
	}
};

export const getTotalNumberProjectCarbon = async (filter = {}) => {
	try {
		const query = gql`
			query ($filter: AdvancedGlobalFilterInput) {
				GetTotalNumberProjectCarbon(Filter: $filter)
			}
		`;

		const {
			data: { GetTotalNumberProjectCarbon }
		} = await apollo.query({
			query,
			variables: {
				filter
			},
			fetchPolicy: 'no-cache'
		});

		return GetTotalNumberProjectCarbon;
	} catch (e) {
		console.log(e);
	}
};

export const addUpdCarbonTemplate = async (carbonTemplateId = 0, carbonTemplateInput = {}) => {
	try {
		const mutation = gql`
			mutation ($carbonTemplateId: ID, $carbonTemplateInput: CarbonTemplateInput!) {
				AddUpdCarbonTemplate(CarbonTemplateId: $carbonTemplateId, CarbonTemplateInput: $carbonTemplateInput)
			}
		`;

		const {
			data: { AddUpdCarbonTemplate }
		} = await apollo.mutate({
			mutation,
			variables: {
				carbonTemplateId: +carbonTemplateId,
				carbonTemplateInput
			}
		});

		return AddUpdCarbonTemplate;
	} catch (e) {
		console.log({ ERROR_ADD_UPD_CARBON_TEMPLATE: e });
	}
};

export const getCarbonTemplate = async (carbonTemplateId) => {
	try {
		const query = gql`
      query ($carbonTemplateId: ID!) {
        GetCarbonTemplate(CarbonTemplateId: $carbonTemplateId) {
          ${RES_CARBON_TEMPLATE}
        }
      }
    `;

		const {
			data: { GetCarbonTemplate }
		} = await apollo.query({
			query,
			variables: {
				carbonTemplateId: +carbonTemplateId
			},
			fetchPolicy: 'no-cache'
		});

		return GetCarbonTemplate;
	} catch (e) {
		console.log({ ERROR_GET_CARBON_TEMPLATE: e });
	}
};

export const getCarbonTemplates = async (filter, hideEmptyName = false) => {
	try {
		const query = gql`
      query($filter: AdvancedGlobalFilterInput, $hideEmptyName: Boolean) {
        GetCarbonsTemplates(Filter: $filter, HideEmptyName: $hideEmptyName) {
          ${RES_CARBON_TEMPLATE}
        }
      }
    `;

		const {
			data: { GetCarbonsTemplates }
		} = await apollo.query({
			query,
			variables: {
				filter,
				hideEmptyName
			},
			fetchPolicy: 'no-cache'
		});

		return GetCarbonsTemplates;
	} catch (e) {
		console.log({ ERROR_GET_CARBON_TEMPLATES: e });
	}
};

export const deleteCarbonTemplate = async (carbonTemplateId) => {
	const mutation = gql`
		mutation ($carbonTemplateId: ID!) {
			DelCarbonTemplate(CarbonTemplateId: $carbonTemplateId)
		}
	`;

	const {
		data: { DelCarbonTemplate }
	} = await apollo.mutate({
		mutation,
		variables: {
			carbonTemplateId: +carbonTemplateId
		}
	});

	return DelCarbonTemplate;
};

export const runAutomation = async (carbonTemplateId = null, runAll, carbonId = null) => {
	const mutation = gql`
    mutation ($carbonTemplateId: ID, $runAll: Boolean!, $carbonId: [ID]) {
      RunAutomationOfCarbonTemplate(CarbonTemplateId: $carbonTemplateId, RunAll: $runAll, CarbonId: $carbonId) {
        id
        nbrProcessed
        nbrUpdate
        nbrNotUpdate
        log
        carbons {
          ${PROJECT_CARBON_FIELD}
        }
      }
    }
  `;

	const {
		data: { RunAutomationOfCarbonTemplate }
	} = await apollo.mutate({
		mutation,
		variables: {
			carbonTemplateId: +carbonTemplateId,
			runAll,
			carbonId
		}
	});

	return RunAutomationOfCarbonTemplate;
};

export const importCarbonClapExternalSource = async (docXid, importDetails = false, runAutomation = false) => {
	const mutation = gql`
    mutation ($docXid: [String!]!, $importDetails: Boolean!, $runAutomation: Boolean!) {
      ImportCarbonClapExternalSource(DocXid: $docXid, ImportDetails: $importDetails, RunAutomation: $runAutomation) {
        id
        status
        message
        carbons {
          ${PROJECT_CARBON_FIELD}
        }
      }
    }
  `;

	const {
		data: { ImportCarbonClapExternalSource }
	} = await apollo.mutate({
		mutation,
		variables: {
			docXid,
			importDetails,
			runAutomation
		}
	});

	return ImportCarbonClapExternalSource;
};

export const checkIfCarbonTemplateExist = async () => {
	try {
		const query = gql`
			query {
				CheckIfCarbonTemplateExist
			}
		`;

		const {
			data: { CheckIfCarbonTemplateExist }
		} = await apollo.query({
			query,
			fetchPolicy: 'no-cache'
		});

		return CheckIfCarbonTemplateExist;
	} catch (e) {
		console.log({ ERROR_CHECK_IF_CARBON_TEMPLATE_EXIST: e });
	}
};

export const changeCarbonVerifiedStatus = async (carbonIds = [], status = 3) => {
	const mutation = gql`
		mutation ($carbonIds: [ID!]!, $status: Int!) {
			ChangeCarbonVerifiedStatus(ArrayCarbonId: $carbonIds, Verified: $status) {
        ${PROJECT_CARBON_FIELD}
      }
		}
	`;

	const {
		data: { ChangeCarbonVerifiedStatus }
	} = await apollo.mutate({
		mutation,
		variables: {
			carbonIds,
			status
		}
	});

	return ChangeCarbonVerifiedStatus;
};

export const getCarbonFiles = async (carbonId) => {
	try {
		const query = gql`
			query ($carbonId: ID!) {
				GetPictureXidOfCarbon(CarbonId: $carbonId)
			}
		`;

		const {
			data: { GetPictureXidOfCarbon }
		} = await apollo.query({
			query,
			variables: {
				carbonId: +carbonId
			},
			fetchPolicy: 'no-cache'
		});

		return GetPictureXidOfCarbon;
	} catch (error) {
		console.error({ error });
	}
};

export const addUpdSliceCarbon = async (sliceCarbon = [], entityType = 0, entityId = 0) => {
	const mutation = gql`
    mutation ($sliceCarbon: [CarbonInput!]!, $entityType: Int!, $entityId: Int!) {
      AddUpdSliceCarbon(NewSliceCarbon: $sliceCarbon, EntityType: $entityType, EntityId: $entityId) {
        ${PROJECT_CARBON_FIELD}
      }
    }
  `;

	const {
		data: { AddUpdSliceCarbon }
	} = await apollo.mutate({
		mutation,
		variables: {
			sliceCarbon,
			entityType: +entityType,
			entityId: +entityId
		}
	});

	return AddUpdSliceCarbon;
};

export const delCarbon = async (carbonIds = []) => {
	const mutation = gql`
		mutation ($carbonIds: [ID!]!) {
			DelCarbon(CarbonId: $carbonIds)
		}
	`;

	const {
		data: { DelCarbon }
	} = await apollo.mutate({
		mutation,
		variables: {
			carbonIds
		}
	});

	return carbonIds;
};

export const checkIfEntityCarbonHaveDocument = async (entityId = 0, entityType = 0) => {
	const mutation = gql`
		mutation ($entityId: ID!, $entityType: Int!) {
			CheckIfEntityCarbonHaveDocument(EntityId: $entityId, EntityType: $entityType)
		}
	`;

	const {
		data: { CheckIfEntityCarbonHaveDocument }
	} = await apollo.mutate({
		mutation,
		variables: {
			entityId: +entityId,
			entityType: +entityType
		}
	});

	return CheckIfEntityCarbonHaveDocument;
};

export const getPortfolios = async () => {
	try {
		const {
			data: { GetPortfolios }
		} = await apollo.query({
			query: gql`
				query {
					GetPortfolios {
						id
						totalCO2
						porfolio {
							id
							createdAt
							updatedAt
							name
							description
							projects {
								id
								createdAt
								updatedAt
								share
								name
								description
								offsetMethodName
								offsetMethodImageXid
								offsetMethodDescription
								projectImageXid
								portfolioId
							}
							pricePerTon
							imageXid
							climateImpactScore
						}
						updatedAt
						createdAt
					}
				}
			`,
			fetchPolicy: 'network-only'
		});
		console.log('carbon removal', GetPortfolios);
		return GetPortfolios;
	} catch (error) {
		console.log(error);
		GlobalMixin.resolveGqlError(error);
	}
};

export const SendKlimateValidationEmailToTGSSales = async (PorfolioId, DesiredValue, TotalAmount) => {
	try {
		const mutation = gql`
			mutation ($PorfolioId: ID!, $DesiredValue: Float!, $TotalAmount: Float!) {
				SendKlimateValidationEmailToTGSSales(PorfolioId: $PorfolioId, DesiredValue: $DesiredValue, TotalAmount: $TotalAmount)
			}
		`;

		const {
			data: { SendKlimateValidationEmailToTGSSales }
		} = await apollo.mutate({
			mutation,
			variables: {
				PorfolioId: +PorfolioId,
				DesiredValue: +DesiredValue,
				TotalAmount: +TotalAmount
			}
		});

		return SendKlimateValidationEmailToTGSSales;
	} catch (error) {
		console.log(error);
		GlobalMixin.resolveGqlError(error);
	}
};
export const migrateProjectToCarbonClap = async (reporting = false) => {
	const mutation = gql`
		mutation ($reporting: Boolean!) {
			MigrateProjectToCarbonClap(Reporting: $reporting)
		}
	`;

	const {
		data: { MigrateProjectToCarbonClap }
	} = await apollo.mutate({
		mutation,
		variables: {
			reporting
		}
	});

	return MigrateProjectToCarbonClap;
};

export const updCarbonClap = async (reporting = false, updCarbonClap) => {
	const mutation = gql`
		mutation ($reporting: Boolean!, $updCarbonClap: CarbonClapInput) {
			UpdCarbonClap(Reporting: $reporting, UpdCarbonClap: $updCarbonClap)
		}
	`;
	console.log('CARBON CLAP UPD', updCarbonClap);
	const {
		data: { UpdCarbonClap }
	} = await apollo.mutate({
		mutation,
		variables: {
			reporting,
			updCarbonClap
		}
	});

	return UpdCarbonClap;
};

export const estimatePremiseElectricityEnergyConsumption = async (SurfaceArea, NbOccupationDay) => {
	const mutation = gql`
		mutation ($surfaceArea: Float!, $nbOccupationDay: Float!) {
			EstimatePremiseElectricityEnergyConsumption(SurfaceArea: $surfaceArea, NbOccupationDay: $nbOccupationDay)
		}
	`;

	const {
		data: { EstimatePremiseElectricityEnergyConsumption }
	} = await apollo.mutate({
		mutation,
		variables: {
			surfaceArea: SurfaceArea,
			nbOccupationDay: NbOccupationDay
		}
	});

	return EstimatePremiseElectricityEnergyConsumption;
};

export const estimatePremiseHeatingEnergyConsumption = async (SurfaceArea, NbOccupationDay) => {
	const mutation = gql`
		mutation ($surfaceArea: Float!, $nbOccupationDay: Float!) {
			EstimatePremiseHeatingEnergyConsumption(SurfaceArea: $surfaceArea, NbOccupationDay: $nbOccupationDay)
		}
	`;

	const {
		data: { EstimatePremiseHeatingEnergyConsumption }
	} = await apollo.mutate({
		mutation,
		variables: {
			surfaceArea: SurfaceArea,
			nbOccupationDay: NbOccupationDay
		}
	});

	return EstimatePremiseHeatingEnergyConsumption;
};

export const estimateLocationElectricityEnergyConsumption = async (SurfaceArea, AncillarySurfaceArea, NbOccupationDay) => {
	const mutation = gql`
		mutation ($surfaceArea: Float!, $ancillarySurfaceArea: Float!, $nbOccupationDay: Float!) {
			EstimateLocationElectricityEnergyConsumption(
				SurfaceArea: $surfaceArea
				AncillarySurfaceArea: $ancillarySurfaceArea
				NbOccupationDay: $nbOccupationDay
			)
		}
	`;

	const {
		data: { EstimateLocationElectricityEnergyConsumption }
	} = await apollo.mutate({
		mutation,
		variables: {
			surfaceArea: SurfaceArea,
			ancillarySurfaceArea: AncillarySurfaceArea,
			nbOccupationDay: NbOccupationDay
		}
	});

	return EstimateLocationElectricityEnergyConsumption;
};

export const estimateLocationHeatingEnergyConsumption = async (SurfaceArea, AncillarySurfaceArea, NbOccupationDay) => {
	const mutation = gql`
		mutation ($surfaceArea: Float!, $ancillarySurfaceArea: Float!, $nbOccupationDay: Float!) {
			EstimateLocationHeatingEnergyConsumption(SurfaceArea: $surfaceArea, AncillarySurfaceArea: $ancillarySurfaceArea, NbOccupationDay: $nbOccupationDay)
		}
	`;

	const {
		data: { EstimateLocationHeatingEnergyConsumption }
	} = await apollo.mutate({
		mutation,
		variables: {
			surfaceArea: SurfaceArea,
			ancillarySurfaceArea: AncillarySurfaceArea,
			nbOccupationDay: NbOccupationDay
		}
	});

	return EstimateLocationHeatingEnergyConsumption;
};

export const estimateAreaNumberOfAirConditioner = async (SurfaceArea) => {
	const mutation = gql`
		mutation ($surfaceArea: Float!) {
			EstimateAreaNumberOfAirConditioner(SurfaceArea: $surfaceArea)
		}
	`;

	const {
		data: { EstimateAreaNumberOfAirConditioner }
	} = await apollo.mutate({
		mutation,
		variables: {
			surfaceArea: SurfaceArea
		}
	});

	return EstimateAreaNumberOfAirConditioner;
};

export const getCarbonCategoryWithKeywords = async (language = 0) => {
	try {
		const {
			data: { GetCarbonCategoryWithKeywords }
		} = await apollo.query({
			query: gql`
				query ($Language: Int!) {
					GetCarbonCategoryWithKeywords(Language: $Language) {
						number
						value
						message
					}
				}
			`,
			variables: {
				Language: language
			}
		});
		return GetCarbonCategoryWithKeywords;
	} catch (error) {
		console.log(error);
	}
};
